import React from "react";
import "./IconCard.scss";

const IconCard = (props) => {
  return (
    <>
      <img
        className={`icon-card-image ${props.classname}`}
        src={props.icon}
        alt={props.altTxt}
      />
      <div className={`icon-card-heading ${props.headingclassname}`}>
        {props.heading}
      </div>
      {props?.subtitle && <div className="icon-subtitle">{props.subtitle}</div>}
    </>
  );
};

export default IconCard;
